import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import Vapi from "@vapi-ai/web";

// Initialize Vapi with your API key
const vapi = new Vapi("5758e5c6-15a6-4d74-a133-83da5ed7251d");

function App() {
  const [callActive, setCallActive] = useState(false);
  const [messages, setMessages] = useState([]);
  const callRef = useRef(null);

  useEffect(() => {
    if (callActive) {
      startCall();
    } else if (callRef.current) {
      endCall();
    }
  }, [callActive]);

  const startCall = async () => {
    try {
      const call = await vapi.start("4e7189b4-3738-4203-8779-ef5acf980ae8");

      // You can add more event listeners or controls for the call here
    } catch (error) {
      console.error("Error starting call:", error);
      setCallActive(false);
    }
  };

  const endCall = async () => {
    try {
      await vapi.stop();
      callRef.current = null;
      console.log("Call ended");
    } catch (error) {
      console.error("Error ending call:", error);
    }
  };

  const toggleCall = () => {
    setCallActive(!callActive);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="profile-picture">
          <img src="https://pbs.twimg.com/profile_images/1709748513565810689/rO356hOB_400x400.jpg" alt="Sergio Massa" />
        </div>
        <h1>Hablá con Sergio</h1>
        <button 
          className={`fancy-button ${callActive ? 'active' : ''}`} 
          onClick={toggleCall}
        >
          <span className="button-text">
            {callActive ? "End Call" : "Llamar"}
          </span>
        </button>
        {callActive && <p>Call is active</p>}
        
        <br/>
          Desarrollado por OctaTech Studio
        
      </header>
    </div>
  );
}

export default App;